.formio-component-select, .formio-component-selectComponentExtended {

  .formio-choices {
    &[data-type='select-multiple'] {
      .form-control {
        min-height: 2.6rem;
        max-width: 18rem;
      }
    }

    .form-control {
      padding-bottom: 0;
      padding-right: 0.75rem;
    }

    .choices__item--selectable:not(.choices__item--choice) {
      max-width: 14rem;
    }

    .choices__list:not(.choices__list--multiple) {

      .formio-choices:not(.is-disabled):hover {
        .choices__item--selectable:not(.choices__item--choice) {
          max-width: fit-content;
          background: var(--background);
        }
      }

    }

    &:not(.is-disabled):hover {
      .choices__item--selectable:not(.choices__item--choice) {
        max-width: fit-content;
      }
    }
  }

  .choices__list--multiple {
    .choices__item {
      height: fit-content;
      line-height: 1.3;
      border-radius: 0.35rem;
      background: #222325;
      border-color: #222325;
      padding-left: 0.9rem;

      .choices__button {
        border-left-color: white;
      }

      @media (max-width: 1000px) {
        display: inline-flex;
        align-items: center;
        font-size: 8px;

        span {
          font-size: 8px;
        }
      }
    }
  }

  .is-open {
    .choices__list--dropdown {
      border: 0.09rem solid #dadee2;
      box-shadow: 0 0.6rem 1.8rem rgba(27, 49, 82, 0.21);
    }
  }

  div.form-control[disabled="disabled"],
  .is-disabled {
    .choices__item.choices__item--selectable {
      white-space: break-spaces;
      padding-right: 0;
    }

    .choices__list--single {
      height: 100%;

      .choices__item--selectable {
        height: 100%;
        display: flex;
        align-items: center;
        font-size: $main-font-size;
        line-height: 125%;;
        font-weight: 400;

        span {
          font-size: $main-font-size;
          white-space: normal;
        }
      }
    }
  }

  .choices__input {
    appearance: none;
  }

  .choices[data-type*=select-one]:after {
    border: none;
    background: url('../../icons/select-arrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 1.2rem;
    height: 1rem;
    margin-top: 0;
    top: 37%;
  }

  .choices[data-type*=select-one].is-open:after {
    margin-top: 0;
  }

  .dropdown {
    background: var(--input-bg);
  }

  .choices__list--dropdown {
    background: var(--input-bg);
    min-width: 130px;
  }

  .choices__input {
    background: var(--input-bg) !important;
    border-color: var(--input-border-color) !important;

    &:hover {
      border-color: var(--input-border-color-hover) !important;
    }

    font-size: 1.1rem;
    padding: 0.7rem !important;
  }

  .choices__list--dropdown .choices__item--selectable.is-highlighted {
    background: var(--selected-select-item);
  }

  .choices__list--dropdown {
    .choices__item--selectable[role="option"] {
      white-space: break-spaces;
      word-break: auto-phrase;
      font-size: 1.1rem;
      padding: 0.7rem;
    }
  }

  .choices__list--dropdown .choices__item.has-no-choices, .choices__list--dropdown .choices__item.has-no-results {
    font-size: 1.1rem;
    padding: 0.7rem;
    word-break: break-word;
  }

  &.formio-component-stopFactors {
    .choices {
      .choices__list.choices__list--multiple {
        .choices__item.choices__item--selectable {
          max-width: fit-content;
          white-space: nowrap;
        }
      }
    }
  }
}


.formio-component-select .choices[data-type*=select-one]:after {
  width: 12px;
  top: 40%;
}

.choices__list.choices__list--single {
  .choices__item.choices__item--selectable {
    padding-right: 32px;

    .choices__button {
      margin-right: 22px;
      background-size: 8px;
      top: 50%;
    }
  }
}


@media (max-width: 1100px) {
  .formio-component-select .choices[data-type*=select-one]:after {
    width: 8.5px;
    right: 6px;
  }

  .choices__list.choices__list--single {
    display: grid;

    .choices__item.choices__item--selectable {
      padding-right: 20px;

      .choices__button {
        margin-right: 10px;
        background-size: 6px;
      }
    }
  }
}
